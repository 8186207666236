//@import './breakpoints';
@import "./variables";

/* subquestion selector*/
.subquestion-container {
  .btn {
    text-decoration: none;
    font-size: 16px;
  }

  .btn.btn-primary[is-selected="false"] {
    color: #000000;
    background-color: #f8f8f8;
    border: 1px solid rgb(165, 165, 165);
  }

  .section-card.btn.btn-primary:disabled:hover {
    background-color: #00695c;
  }

  .btn.btn-primary[is-selected="true"] {
    color: #000000;
    background-color: rgb(127, 187, 196);
    border: 0;
  }
}
