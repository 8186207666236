//@import './breakpoints';
@import "./variables";
//$smallerThanIpad: 767px;

table#state-survey tbody > tr:first-child,
table#state-survey tbody > tr:first-child td:first-child * {
  font-size: 1.3rem;
  font-weight: bold;
}
/* table*/
table.table {
  font-size: 1.05rem;
}

table,
td {
  border: 1px solid #8d8d8d;
  border-collapse: collapse;
  text-align: center;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: white;
}
.table-striped tbody tr a {
  text-decoration: none;
}
table.table-striped tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-sm td {
  padding: 8px 10px;
}

.main-table-header {
  font-family: sans-serif !important;
  font-weight: 100;
}

.table th.th-secondary {
  vertical-align: top;
  border: 1px solid #8d8d8d;
}

.th {
  background-color: black;
  color: white;
  /* border: 1px solid #fff; */
  border-top-left-radius: 24px;
  border-top-right-radius: 20px;
  padding: 10px;
  width: 200px;
  text-align: center;
  font-size: large;
  height: 48px;

  &__nobackground {
    color: lightblue;
    background-color: white;
    border: none;
    padding: 10px;
    width: 200px;
    text-align: left;
    font-size: 1rem;
    font-weight: normal;
  }
}
