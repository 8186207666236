body {
  position: relative;
  left: 0px;
  color: #555555;
  min-height: 100vh;
}

div#root {
  overflow: hidden;
}
footer .agency-footer {
  margin-bottom: 0;
}

//App css - header
.site-title {
  text-decoration: none !important;
  color: white !important;
  font-size: 24px !important;
}

.nav-link-container > .col-1 {
  text-align: center;
}

h1.tile-header {
  font-size: 27px;
  display: flex;
  color: #555;
}

.tile-header2 {
  font-size: 19px;
  font-weight: 400;
}

.help-link a {
    color: white;
    color: white !important;
    font-size: 13px;
    text-decoration: none;
}

.help-link span {
    font-size: 24px;
}

// Dashboard styles
#dashboard {
  margin-top: 31px;
  align-items: flex-start;
}

#clear-modal {
  position: absolute;
  height: 100%;
  width: 100vw;
  display: block;
  top: 0;
  left: 0;
  z-index: 8;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.custom-select {
  .option-container {
    border: 1px solid #ced4da;
    width: 400px;
    position: relative;
    background-color: white;
    left: -14px;
    top: 15px;
    visibility: hidden;
    z-index: 9;
    overflow-y: scroll;
    box-shadow: -2px 4px 7px #3f3f3f;
    height: 300px;
  }
  > .option:first-child {
    padding: 0;
    border-bottom: 0;
  }
  .option {
    position: relative;
    line-height: 1.4rem;
    padding: 0.7rem;
    border-bottom: 1px solid #ced4da;
    left: 0;
    cursor: pointer;
    font-size: 1rem;
  }
  .option:not(.default):hover {
    background: rgb(7, 139, 215);
    color: #fff;
  }
}
.custom-select {
  position: relative;
}
.topic-icon::before {
  font-size: 24px;
}
.masonry-column > div:last-child {
  .option-container {
    padding: 0;
    border-bottom: 0;
    top: 45px;
    left: auto;
    right: -3px;
    position: absolute;
  }
}

.selectpicker.form-control {
  text-align: left;
  font-size: 1rem;
  option {
  }
}

// table styles
.table-responsive .table th.th {
  background-color: #00788a;
  border-bottom: none;
  border-top: none;
}
.btn-info {
  border: none;
}
th.th__nobackground {
  border-top: none;
  color: #00788a;

  span {
    font-size: 1.26rem;
    font-weight: 500;
  }
}
.table-responsive table {
  border: none;
}
.col-md-3.sidebar {
  background: #f2f2f2;
  max-width: 250px;
}
.sub-viz-type-toggle {
  display: flex;
}

#nav-header {
  background-color: #712177;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  #top-nav-row {
    padding: 0 15px;

    nav > button.btn.btn-link {
      padding: 15px 0;
      color: white;
      font-size: 21px;
    }
  }

  #bottom-nav-row {
    width: 100%;
    display: flex;
    justify-content: center;
    nav {
      width: 100%;
    }
    ol {
      padding: 15px 0;
    }
  }
}
.masonry-tile__icon {
  display: flex;
  justify-content: center;

  .topic-icon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

// Sidebar styles
@media (max-width: 992px) {
  .col-md-3.sidebar {
    background: #f2f2f2;
    max-width: 100%;
  }
  .col-sm-12 {
    display: block;
  }
  .sub-viz-type-toggle {
    display: block;
  }
  .removeLeftBorderRadius,
  .removeRightBorderRadius {
    border-radius: 0.25rem;
  }

  .masonry-column > div:last-child .option-container {
    right: 12px;
  }
  .custom-select .option-container {
    width: 100%;
  }
}

.sidebar .sidebar-sticky > .nav-link {
  text-decoration: underline;
}

// Print and CSV style

.genBtn {
  background-color: #00788a;
  border-radius: 5px !important;
  padding: 5px 10px 5px 10px !important;
  font-size: 18px !important;
  width: 100% !important;
}
