//breakpoints
$xs-max: "575px";
$sm: "576px";
$sm-down: "767px";
$md: "768px";
$md-down: "991px";
$lg: "992px";
$xl: "1200px";
$xxl: "1440px";

// standard colors
$white: #fff !default;
$black: #000 !default;
$red: #8f0000 !default;
$green: #80ff80 !default;
$yellow: #ffff80 !default;
$orange: orange !default;

$cdcblue: #075290;

// font styling and type

$monospace: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
$opensans: "Open Sans", apple-system, BlinkMacSystemFont, "Segoe UI",
  "Helvetica Neue", Arial, sans-serif;

$link: #075290;

// used on table.scss  and subquestion selector.scss and topic.scss
$smallerThanIpad: 767px;
$top-padding: 15px;

// ----- Profile Categories custom colors -----//

// Not selected theme color
$cat-not-selected-fontcolor: #4d4d4d;
$cat-not-selected-bgcolor: rgba(189, 189, 189, 1);
$cat-not-selected-bgcolor-hover: rgb(0, 81, 94);
$cat-not-selected-bgcolor-focus: #066194;

// T01 Category Health Education theme color
$cat-T01-bgcolor: #066194;
$cat-T01-bgcolor-hover: rgb(0, 81, 94);
$cat-T01-bgcolor-focus: #066194;

// T02 Category Physical Education & Physical Activity theme color
$cat-T02-bgcolor: #3c4c60;
$cat-T02-bgcolor-hover: #44392f;
$cat-T02-bgcolor-focus: #665546;

// T03 Category Youth Development Programs theme color
$cat-T03-bgcolor: #444444;
$cat-T03-bgcolor-hover: #35265a;
$cat-T03-bgcolor-focus: #594099;

// T04 Category Family Engagement theme color
$cat-T04-bgcolor: #9e3a3a;
$cat-T04-bgcolor-hover: rgb(93, 46, 95);
$cat-T04-bgcolor-focus: #3c4c60;

// T05 Category Nutrition Environment & Services theme color
$cat-T05-bgcolor: #286665;
$cat-T05-bgcolor-hover: rgb(94, 20, 1);
$cat-T05-bgcolor-focus: #444444;

// T06 Category Healthy & Safe School Environment theme color
$cat-T06-bgcolor: #4d4a83;
$cat-T06-bgcolor-hover: rgb(1, 63, 114);
$cat-T06-bgcolor-focus: #005dab;

// T07 Category Health Services theme color
$cat-T07-bgcolor: #c0532c;
$cat-T07-bgcolor-hover: #334d15;
$cat-T07-bgcolor-focus: #578222;

// T08 Category School Health Coordination theme color
$cat-T08-bgcolor: #6c2865;
$cat-T08-bgcolor-hover: rgb(114, 55, 3);
$cat-T08-bgcolor-focus: #bc5b06;

// T10 Category School Health Coordination
$cat-T10-bgcolor: #80783a;
$cat-T10-bgcolor-hover: rgb(114, 55, 3);
$cat-T10-bgcolor-focus: #bc5b06;

// T11 Category Before & After School Programs theme color
$cat-T11-bgcolor: #714e26;
$cat-T11-bgcolor-hover: rgb(114, 55, 3);
$cat-T11-bgcolor-focus: #bc5b06;

// theme colors
$primary: #005eaa !default;
$secondary: #88c3ea !default;
$tertiary: #c0e9ff !default;

$purple-primary: #712177 !default;
$purple-secondary: #b890bb !default;
$purple-tertiary: #e3d3e4 !default;

$brown-primary: #705043 !default;
$brown-secondary: #ad907b !default;
$brown-tertiary: #d7ccc8 !default;

$teal-primary: #00695c !default;
$teal-secondary: #4ebaaa !default;
$teal-tertiary: #ceece7 !default;

$pink-primary: #af4448 !default;
$pink-secondary: #e57373 !default;
$pink-tertiary: #ffc2c2 !default;

$orange-primary: #bb4d00 !default;
$orange-secondary: #ffad42 !default;
$orange-tertiary: #ffe97d !default;

$slate-primary: #29434e !default;
$slate-secondary: #7e9ba5 !default;
$slate-tertiary: #b6c6d2 !default;

$indigo-primary: #26418f !default;
$indigo-secondary: #92a6dd !default;
$indigo-tertiary: #dee8ff !default;

$cyan-primary: #007c91 !default;
$cyan-secondary: #65b0bd !default;
$cyan-tertiary: #cce5e9 !default;

$green-primary: #4b830d !default;
$green-secondary: #84bc49 !default;
$green-tertiary: #dcedc8 !default;

$amber-primary: #fbab18 !default;
$amber-secondary: #ffd54f !default;
$amber-tertiary: #ffecb3 !default;

// engagement colors
// TODO: TO BE DEFINED
$success: #28a745 !default;
$info: #17a2b8 !default;
$warning: #ffc107 !default;
$danger: #dc3545 !default;
$light: #f8f9fa !default;
$dark: #343a40 !default;
$muted: #696969 !default;

// alerts
$alert-red: #870808 !default;

// grays
$graydarker: #333 !default;
$graydark: #555 !default;
$gray: #bdbdbd !default;
$graylight: #e0e0e0 !default;
$graylighter: #f0f0f0 !default;
$graylightest: #f5f5f5 !default;

// file colors
$epub: #86b916 !default;
$excel: #207245 !default;
$govd: #5a5a5a !default;
$pdf: #c1272d !default;
$ppt: #d24625 !default;
$fb: #3d5a98 !default;

// font sizes
$fs4: 4rem;
$fs35: 3.5rem;
$fs3: 3rem;
$fs25: 2.5rem;
$fs2: 2rem;
$fs15: 1.5rem;
$fs14: 1.4rem;
$fs13: 1.3rem;
$fs12: 1.2rem;
$fs11: 1.1rem;
$fs1: 1rem;
$fs0875: 0.875rem;
$fs08: 0.8rem;
$fs07: 0.7rem;

// sizes
$s025: 0.25rem;
$s05: 0.5rem;
$s1: 1rem;
$s15: 1.5rem;
$s18: 1.8rem;
