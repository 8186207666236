@font-face {
  font-family: "icomoon";
  /* src: url("fonts/icomoon.svg") format("svg"); */
  /* src:  url('fonts/icomoon.eot?7i7js0'); */
  src: url("fonts/icomoon.eot?7i7js0#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?7i7js0") format("truetype"),
    url("fonts/icomoon.woff?7i7js0") format("woff"),
    url("fonts/icomoon.svg?7i7js0#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-u2:before {
  content: "\e900";
}
.icon-u3:before {
  content: "\e901";
}
.icon-u4:before {
  content: "\e902";
}
.icon-u20:before {
  content: "\e903";
}
.icon-u0:before {
  content: "\e904";
}
.icon-u1:before {
  content: "\e905";
}
.icon-u16:before {
  content: "\e906";
}
.icon-u21:before {
  content: "\e907";
}
.icon-u5:before {
  content: "\e908";
}
.icon-u6:before {
  content: "\e909";
}
.icon-u19:before {
  content: "\e90a";
}
