@import "./variables";
// Tile Colors //
.masonry-tile__wrapper {
  background-color: #5a5f38;
}
.yrbs-cat__color {
  &--NotSelected {
    background-color: $cat-not-selected-bgcolor;
    color: $cat-not-selected-fontcolor;

    &.btn {
      &:hover {
        background-color: $cat-not-selected-bgcolor-hover;
      }

      &:focus {
        background-color: $cat-not-selected-bgcolor-focus;
      }
    }
  }

  &--T01 {
    // Health Education
    background-color: $cat-T01-bgcolor;
    color: $white;
    //background-clip: padding-box;

    &.btn {
      &:hover {
        background-color: $cat-T01-bgcolor-hover;
      }

      &:focus {
        background-color: $cat-T01-bgcolor-focus;
      }
    }
  }

  &--T02 {
    // Physical Education & Physical Activity
    background-color: $cat-T02-bgcolor;
    background-clip: padding-box;
    color: $white;

    &.btn {
      &:hover {
        background-color: $cat-T02-bgcolor-hover;
      }

      &:focus {
        background-color: $cat-T02-bgcolor-focus;
        color: $white;
      }
    }
  }

  &--T03 {
    // Youth Development Programs
    background-color: $cat-T03-bgcolor;
    background-clip: padding-box;
    color: $white;

    &.btn {
      &:hover {
        background-color: $cat-T03-bgcolor-hover;
      }

      &:focus {
        background-color: $cat-T03-bgcolor-focus;
      }
    }
  }

  &--T04 {
    // Family Engagement
    background-color: $cat-T04-bgcolor;
    background-clip: padding-box;
    color: $white;

    &.btn {
      &:hover {
        background-color: $cat-T04-bgcolor-hover;
      }

      &:focus {
        background-color: $cat-T04-bgcolor-focus;
      }
    }
  }

  &--T05 {
    // Nutrition Environment & Services
    background-color: $cat-T05-bgcolor;
    background-clip: padding-box;
    color: $white;

    &.btn {
      &:hover {
        background-color: $cat-T05-bgcolor-hover;
      }

      &:focus,
      &:active {
        background-color: $cat-T05-bgcolor-focus;
        color: $white;
      }
    }
  }

  &--T06 {
    // Healthy & Safe School Environment
    background-color: $cat-T06-bgcolor;
    background-clip: padding-box;
    color: $white;

    &.btn {
      &:hover {
        background-color: $cat-T06-bgcolor-hover;
      }

      &:focus {
        background-color: $cat-T06-bgcolor-focus;
      }
    }
  }

  &--T07 {
    // Health Services
    background-color: $cat-T07-bgcolor;
    background-clip: padding-box;
    color: $white;

    &.btn {
      &:hover {
        background-color: $cat-T07-bgcolor-hover;
      }

      &:focus {
        background-color: $cat-T07-bgcolor-focus;
      }
    }
  }

  &--T08 {
    //  School Health Coordination
    background-color: $cat-T08-bgcolor;
    background-clip: padding-box;
    color: $white;

    &.btn {
      &:hover {
        background-color: $cat-T08-bgcolor-hover;
      }

      &:focus {
        background-color: $cat-T08-bgcolor-focus;
      }
    }
  }

  &--T09 {
    //    Before & After School Programs
    background-color: $cat-T08-bgcolor;
    background-clip: padding-box;
    color: $white;

    &.btn {
      &:hover {
        background-color: $cat-T08-bgcolor-hover;
      }

      &:focus {
        background-color: $cat-T08-bgcolor-focus;
      }
    }
  }
  &--T10 {
    //    Before & After School Programs
    background-color: $cat-T10-bgcolor;
    background-clip: padding-box;
    color: $white;
  }
  &--T11 {
    //    Before & After School Programs
    background-color: $cat-T08-bgcolor;
    background-clip: padding-box;
    color: $white;

    &.btn {
      &:hover {
        background-color: $cat-T08-bgcolor-hover;
      }

      &:focus {
        background-color: $cat-T08-bgcolor-focus;
      }
    }
  }

  &--none {
    background: $gray;
    background-clip: padding-box;
    color: $black;

    &.btn {
      &:hover {
        background-color: darkgrey;
        color: $black;
      }

      &:focus,
      &:active {
        background-color: $gray;
        color: $black;
        border: 0px;
      }
    }
  }
}

.yrbs-cat-shade__color {
  &--T01 {
    // Health Education
    background-color: #00c5e3;
    background-clip: padding-box;
  }

  &--T02 {
    // Physical Education & Physical Activity
    background-color: #bdac9e;
    background-clip: padding-box;
  }

  &--T03 {
    // Youth Development Programs
    background-color: #dcbbdd;
    background-clip: padding-box;
    // Nutrition Environment & Services
    background-color: #f96f4d;
    background-clip: padding-box;
  }

  &--T04 {
    // Family Engagement
    background-color: #85c8ff;
    background-clip: padding-box;
  }

  &--T05 {
    // Nutrition Environment & Services
    background-color: #b2de7d;
    background-clip: padding-box;
  }

  &--T06 {
    // Healthy & Safe School Environment
    background-color: #dcbbdd;
    background-clip: padding-box;
  }

  &--T07 {
    // Health Services
    background-color: #b7a9db;
    background-clip: padding-box;
  }

  &--T08 {
    // School Health Coordination
    background-color: #f7b072;
    background-clip: padding-box;
  }

  &--T09 {
    // Before & After School Programs
    background-color: #f7b072;
    background-clip: padding-box;
  }
}
