$linkHoverColor: rgb(253, 210, 125);
$listOptionHoverColor: rgb(30, 171, 252);
div#legend-group {
  position: absolute;
  right: 24px;
  top: 80px;
  padding: 11px;
  background-color: white;
}
#legend-group {
  width: 140px;
  border: 1px solid #ccc;
}
.small-circle {
  margin-top: 6px;
  height: 12px;
  width: 12px;
  border-radius: 6px;
  position: relative;
}
#legend-label {
  font-weight: bold;
  text-align: left;
  margin-bottom: 9px;
}
.value-label {
  font-size: 16px;
  padding-left: 9px;
  text-align: left;
}
.diamond {
  border: 1px solid #6a6e71;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
}
.value-group {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
}

div#subquestion-map-container {
  border-radius: 10px;
  border: 1px solid lightgray;
  padding: 23px 40px 40px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;

  .question-header__location-text {
    font-size: 1.25rem;
    text-align: left;
    font-family: sans-serif !important;
  }
}

.location-option:hover {
  background: $listOptionHoverColor;
  color: white;
}
div.tooltip-header {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

#map-controls {
  position: absolute;
  border: 1px solid #ddd;
  padding: 5px;
  background: white;
  top: 120px;
  .map-control {
    position: relative;
    height: 40px;
    width: 40px;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
#tooltip section {
  flex: 0 0 100%;
}

#multi-tooltip {
  section.State {
    margin-bottom: 10px;
  }
}

// Map Styles
#tooltip,
#multi-tooltip {
  background-color: white;
  border: 1px solid lightgray;
  font-size: 0.8rem;
  color: #555;
  border-radius: 8px;

  #multi-tooltip > div {
    padding: 7px;
  }
  .tooltip-header {
    padding: 5px;
    color: rgba(0, 0, 0, 0.75);
  }
  b {
    font-size: 0.8rem;
  }
  #tooltip-link {
    color: #1272c0;
  }
  #tooltip-link:hover {
    text-decoration: underline;
  }
  .close-tooltip {
    position: relative;
    cursor: pointer;
    top: -2px;
    right: 3px;
    font-size: 1.15rem;
    transition: color 0.5s;
  }

  #tooltip-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    section {
      padding: 0px;
      border: 1px solid #555;
      border-radius: 8px;
      overflow: hidden;

      .tooltip-header {
        border: none;
      }

      .tooltip-data {
        padding: 9px;
      }
    }
  }

  .Local {
    flex-wrap: wrap;
    margin-top: 0.5rem;
  }
  .State {
    flex: 0 0 100%;
    border-bottom: 1px solid #ddd;
  }
  .location-type-header {
    flex: 0 0 100%;
    font-size: 1.15rem;
    font-weight: 400;
  }
}

#tooltip #tooltip-content {
  display: block;
}
.tooltip-close-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 14px;
  position: absolute;
  right: 0px;
  top: 10px;
}
#tooltip {
  min-width: 165px;
  border: none;
  section {
    margin-top: 0;
  }
}
#multi-tooltip {
  width: 450px;

  #tooltip-content {
    padding: 8px;
  }

  section {
    flex: 0 0 49%;
  }
}

#State-table {
  table tr#US:first-child button,
  table tr#US {
    font-weight: bold;
    font-size: 1.3rem;
  }
}

#map-reset-button {
  rect,
  text {
    transition: fill 0.25s ease;
  }
  text {
    pointer-events: none;
  }
}

#map-reset-button.hover {
  text {
    fill: white;
  }
}
