.location-option {
  list-style-type: none;
  cursor: pointer;
  position: relative;
  z-index: 10;
  text-align: left;
  padding-left: 20px;
}
.location-dropdown {
  position: relative;
  width: 344px;
  z-index: 9;
  top: 0;
  left: 0px;
  overflow: hidden;
  transition: height 0s;
  padding: 0;

  > div {
    background-color: white;
    position: relative;
    top: -100%;
    left: 0;
    width: 100%;
    border: 1px solid #e2e2e2;
    transition: top ease 0s 0s;
  }

  .main-selection {
    margin: 0;
    padding: 10px 20px;
    font-weight: bold;
    border-top: 1px solid #e2e2e2;
    text-align: left;

    b {
      padding-right: 10px;
    }
  }
}

div.collapsible-list {
  position: relative;
  overflow: hidden;
  height: 0px;
  padding-top: 0;
  transition: height ease 0.7s 0s;

  ul {
    top: -200px;
    padding: 0;
    position: relative;
    transition: top ease 0.79s;
  }
}

@media (max-width: 922px) {
  .location-dropdown {
    width: 100%;
    left: 0px;
  }
}
