﻿//@import './breakpoints';
@import './variables';

.modal {

    .modal-content {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border: none;
    }

    .modal-header {
        background-color: rgb(113, 33, 119);
        padding: 8px;
        color: white;
        padding-left: 20px;
        border: none;
    }

    .modal-header-text {
        font-size: 20px;
    }

    .modal-header button {
        color: white;
        background-color: rgb(113, 33, 119);
        border: none;
        font-size: 20px;
        font-weight: 500;
    }

    .row {
        width: 100%;
    }

    .modal-footer {
        border-top: none;
    }

    button.copy {
        padding-top: 3px;
        padding-bottom: 3px;
    }
}


.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: grey;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    z-index: 1040;
}

