/* data visualization toggle */
.removeLeftBorderRadius {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.removeRightBorderRadius {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.sub-viz-type-toggle {
  .btn {
    border: 0.5px solid #bdbdbd;
    color: #00695c;
    font-size: 17px;
    width: 100%;
  }
  .btn.selected-viz {
    background: rgba(0, 120, 138, 1);
    color: white;
  }
  .col-6 {
    padding: 0px;
  }
}
