﻿//@import './breakpoints';
@import './variables';

.back-to-top {
    position: fixed;
    bottom: 10vh !important;
    right: 0 !important;
    width: 50px !important;
    border: 3px solid #fff !important;
    border-right: none !important;
    border-radius: 30px 0 0 30px !important;
    background-color: $purple-primary;
    color: white;
}
