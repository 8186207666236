// @import './breakpoints';
// @import './variables';
.patterns-over-time-question {
  padding-left: 15px;
}
/* question header*/
h2.question-header__location-text {
  font-weight: 100 !important;
}
.question-header {
  &__select-another-question {
  }

  &__topic-subtopic-header {
    min-height: 150px;
    border-radius: 15px;
    padding: 4px;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    border: 5px solid #fff;
    font-size: large;
    font-weight: lighter;
    text-align: center;

    @media screen and (max-width: $md) {
      width: 100%;
    }

    .tile-header {
      min-height: 50px;

      .topic-description {
        font-weight: 400;
        font-size: 18px;
      }

      .subtopic-description {
        font-weight: 400;
        font-size: 18px;
        font-style: italic;
      }
    }

    h1.question-description {
      background-color: $white;
      color: $black;
      font-size: 27px;
      font-weight: 600;
      text-transform: capitalize;
      margin-bottom: 0;
    }
  }

  &__location-section {
    border-top: 1px solid grey;
    background-color: rgba(242, 242, 242, 1);
    height: 70px;
    border-radius: 0 0 8px 8px;
    color: $black;

    select {
      font-size: smaller;
    }
    @media (max-width: $md) {
      height: 104px;
    }
  }

  &__location-text {
    font-weight: 200;
    font-style: normal;
    font-size: 25px;
    color: #555;
    margin-top: 0;

    .patterns-over-time-header {
      font-size: 28px;
    }

    .patterns-over-time-question {
      color: #555;
      font-size: 19px;
    }
    /*border-top: 1px solid rgb(165, 165, 165);*/
  }
}
