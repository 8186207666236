// @import './breakpoints';
@import "./variables";

/* topic*/
.nav-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .col-2.p-0 {
    display: flex;
    justify-content: center;
  }
}
.nav-link-container > div {
  height: 100%;
  padding: 0 10px 0 10px;
}

span.topic {
  font-size: 15px;
  text-transform: capitalize;
  line-height: 21px;
  padding: 11px 0px;
  color: white;
}

.yrbs-cat__color--NotSelected span.topic {
  color: #4d4d4d;
}

.topic-item-container {
  min-height: 50px;
  width: 100%;
  margin: 5px 0;
  border-radius: 5px;
  font-size: medium;
  line-height: 1;
  color: white;
  background-color: #5a5f38;
  padding: 2px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .topic-icon {
    width: 20px;
  }
  .mx-1 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.sub-topic-selector-container {
  margin-left: 50px;
}
