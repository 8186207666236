//@import './breakpoints';
//@import './variables';

/* dashboard tiles*/
.masonry-tile {
  &__wrapper {
    min-height: 150px;
    border-radius: 15px;
    padding: 2px;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    border: 5px solid #fff;
    font-size: large;
    font-weight: lighter;
    @media screen and (max-width: $md) {
      width: 100%;
    }

    option {
      width: 200px;
      padding: 10px;
    }

    .tile-header {
      min-height: 70px;
      padding-top: 8px;
      padding-bottom: 8px;
      justify-content: space-between;
    }

    .question-description {
      background-color: $white;
      color: $black;
      font-size: 19px;
      font-weight: 100;
      margin: 0;
      line-height: 1.4;
      font-family: "Open Sans", "Segoe UI", "Helvetica Neue", arial, sans-serif;
    }

    .topic-description {
      font-size: 11px;
      font-style: italic;
    }

    .subtopic-description {
      font-size: 14px;
    }

    select {
      font-size: smaller;
    }

    & > * {
      color: white;
    }
  }

  &__links {
    display: block;
    justify-content: space-around;
    margin-top: 2px;
    background: white;
    height: auto;
    border-radius: 0 0 8px 8px;

    .sub-question-text {
      font-size: 15px;
      color: $black;
      margin-bottom: 0;
    }

    .row.table-link-row {
      // padding-top: 0.7rem;
      justify-content: center;
    }

    .row.table-link-row .btn.btn-link {
      color: black;
      font-size: 17px;
    }
  }

  &__icon {
  }
}
